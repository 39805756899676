<template>
  <v-container grid-list-xl style="margin-top: 80px;">
    <v-layout justify-center align-center>
      <view-title />
    </v-layout>
    <v-layout justify-center align-center class="mt-5" v-if="!loading">
      <v-card>
        <div class="Sector-Selector-Container v-card-content">
          <div class="Sector-Selector-Content">
            <v-layout row wrap>
              <v-flex xs12>
                <v-layout justify-start>
                  <div class="H6-Selected-On-Surface-Medium-Emphasis-Center">
                    Select a group to view menus
                  </div>
                </v-layout>
              </v-flex>
              <v-flex xs12 style="margin-top: 40px;">
                <v-layout justify-start>
                  <v-autocomplete
                    :items="sectors"
                    placeholder="Sector"
                    v-on:input="selectSector"
                    :loading="sectors.length === 0"
                  />
                </v-layout>
              </v-flex>
            </v-layout>
            <v-layout justify-start style="margin-top: 25px;">
              <v-btn
                style="margin-left: 0px;"
                color="primary"
                :disabled="!selectedSector"
                @click="goToSector"
                >Go to menus</v-btn
              >
            </v-layout>
          </div>
        </div>
      </v-card>
    </v-layout>
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'SectorsListing',
  data: () => ({
    // TODO remove the defaults here once api is working
    selectedSector: null,
  }),
  computed: {
    ...mapState('adminPanel', ['loading']),
    ...mapGetters('sectors', {
      sectors: 'mappedSectors',
    }),
  },
  methods: {
    goToSector() {
      if (this.selectedSector !== null) {
        this.$router.push({
          name: 'menu-sector-info',
          params: {
            sector_id: this.selectedSector.value,
          },
        });
      } else {
        this.$toast('Please Select a Group');
      }
    },
    selectSector(sectorId) {
      this.selectedSector = this.sectors.find((s) => s.value === sectorId);
    },
  },
  async mounted() {
    this.$store.commit('adminPanel/setViewTitle', {
      depth: 0,
      title: 'Menu Manager',
      to: { name: 'menu-sector-listing' },
    });
    this.$store.commit('adminPanel/setLoading', true);
    this.$store.dispatch('sectors/fetchSectors'); // no await to render page fast
    this.$store.commit('adminPanel/setLoading', false);
  },
};
</script>

<style>
.Sector-Selector-Container {
  width: 773px;
  height: 366px;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 1px 10px 0 rgba(0, 0, 0, 0.12),
    0 4px 5px 0 rgba(0, 0, 0, 0.14);
  background-color: #ffffff;
}
.Sector-Selector-Content {
  padding: 80px 90px 80px 90px;
}
</style>
