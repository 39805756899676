import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VContainer,
    { staticStyle: { "margin-top": "80px" }, attrs: { "grid-list-xl": "" } },
    [
      _c(
        VLayout,
        { attrs: { "justify-center": "", "align-center": "" } },
        [_c("view-title")],
        1
      ),
      !_vm.loading
        ? _c(
            VLayout,
            {
              staticClass: "mt-5",
              attrs: { "justify-center": "", "align-center": "" }
            },
            [
              _c(VCard, [
                _c(
                  "div",
                  { staticClass: "Sector-Selector-Container v-card-content" },
                  [
                    _c(
                      "div",
                      { staticClass: "Sector-Selector-Content" },
                      [
                        _c(
                          VLayout,
                          { attrs: { row: "", wrap: "" } },
                          [
                            _c(
                              VFlex,
                              { attrs: { xs12: "" } },
                              [
                                _c(
                                  VLayout,
                                  { attrs: { "justify-start": "" } },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "H6-Selected-On-Surface-Medium-Emphasis-Center"
                                      },
                                      [
                                        _vm._v(
                                          "\n                  Select a group to view menus\n                "
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ],
                              1
                            ),
                            _c(
                              VFlex,
                              {
                                staticStyle: { "margin-top": "40px" },
                                attrs: { xs12: "" }
                              },
                              [
                                _c(
                                  VLayout,
                                  { attrs: { "justify-start": "" } },
                                  [
                                    _c(VAutocomplete, {
                                      attrs: {
                                        items: _vm.sectors,
                                        placeholder: "Sector",
                                        loading: _vm.sectors.length === 0
                                      },
                                      on: { input: _vm.selectSector }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          VLayout,
                          {
                            staticStyle: { "margin-top": "25px" },
                            attrs: { "justify-start": "" }
                          },
                          [
                            _c(
                              VBtn,
                              {
                                staticStyle: { "margin-left": "0px" },
                                attrs: {
                                  color: "primary",
                                  disabled: !_vm.selectedSector
                                },
                                on: { click: _vm.goToSector }
                              },
                              [_vm._v("Go to menus")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                )
              ])
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }